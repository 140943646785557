import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import FieldGroup from '../FieldGroup';
import styles from './MultipleCheckboxes.module.css';

export default function MultipleCheckboxes({ title, children, ...props }) {
    const localStorageName = `MultipleCheckboxes_${title}`;

    const [open, setOpen] = useState(true);
    const classOpen = open === true ? 'open' : null;
    const didMountRef = useRef(false);

    useEffect(() => {
        const stored = localStorage.getItem(localStorageName);
        if (stored && JSON.parse(stored)?.open === false) {
            setOpen(false);
        }
    }, [localStorageName]);

    useEffect(() => {
        if (didMountRef.current) {
            localStorage.setItem(localStorageName, JSON.stringify({ open: open }));
        }
        didMountRef.current = true;
    }, [localStorageName, open]);

    function handleIsOpen(e) {
        e.preventDefault();
        setOpen(!open);
    }

    return (
        <fieldset className={styles['fieldset']}>
            {title && (
                <legend
                    className={styles[`${classOpen}`]}
                    onClick={handleIsOpen}
                >
                    {title}
                </legend>
            )}

            {open === true && <FieldGroup {...props}>{children}</FieldGroup>}
        </fieldset>
    );
}

MultipleCheckboxes.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
