import React from 'react';

import DownshiftInputField from '@components/global/SearchBar/DownshiftInputField';

import { ReactComponent as SVGIconSearch } from '@public/global/search/search.svg';

import PropTypes from 'prop-types';

import styles from './Search.module.css';

export default function SearchBar({ query, handleOnSubmit, downshiftInputOnChange, callback, buttonProps }) {
    const fnOnSubmit = (e) => {
        e.preventDefault();
        handleOnSubmit(e.target[0].value);
    };

    return (
        <section className={styles.searchBar}>
            <form
                className={styles.form}
                onSubmit={fnOnSubmit}
            >
                <div className={styles['form-group']}>
                    <DownshiftInputField
                        downshiftInputOnChange={downshiftInputOnChange}
                        callback={callback}
                        menuProps={{ className: styles.menu }}
                        inputProps={{ className: styles.input, 'data-ats-resources-search-form': 'query_input' }}
                        value={query}
                    />
                    <button
                        type="submit"
                        value="Submit"
                        className={styles.button}
                        data-ats-resources-search-form="search_button"
                        {...buttonProps}
                    >
                        <SVGIconSearch />
                    </button>
                </div>
            </form>
        </section>
    );
}

SearchBar.propTypes = {
    handleOnSubmit: PropTypes.func.isRequired,
    downshiftInputOnChange: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
};
