import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { canonical } from '@helpers/alternates';

import useTranslation from '@hooks/useTranslation';

export default function JsonLd({ resources }) {
    const { t } = useTranslation('pages/resources/_blocks/results');
    const { locale, pathname } = useRouter();
    const canonicalUrl = canonical(pathname, locale);

    const webpageSchema = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        '@id': canonicalUrl + '#webpage',
        url: canonicalUrl,
        name: t('seo.title'),
        description: t('seo.description'),
        potentialAction: [
            {
                '@type': 'ReadAction',
                target: [canonicalUrl],
            },
        ],
    };
    const itemsSchema = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: resources.map((resource, i) => ({
            '@type': 'ListItem',
            position: i + 1,
            name: resource.title,
            url: resource.url,
            image: resource?.cover?.cover2x,
        })),
    };

    return (
        <Head>
            <script
                id="webpageSchema"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(webpageSchema),
                }}
            />
            <script
                id="itemsSchema"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(itemsSchema),
                }}
            />
        </Head>
    );
}
