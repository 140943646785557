import CustomLink from '@components/global/CustomLink';
import { Button } from '@components/ui/Button';

import noResultsSVG from '@public/pages/resources/global/no-results.svg';

import useTranslation from '@hooks/useTranslation';

import styles from './NoResults.module.css';

export default function NoResults() {
    const { t } = useTranslation('pages/resources/_blocks/results');

    return (
        <div
            className={styles.noResults}
            data-ats-resources-search-results="no_results"
            data-track="search_no_results"
        >
            <img
                className={styles.monster}
                src={noResultsSVG.src}
                width={noResultsSVG.width}
                height={noResultsSVG.height}
                alt="No results"
            />
            <h2>{t('resourcesNoResultsHeader')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('resourcesNoResultsDesc') }}></p>
            <CustomLink
                href={{ pathname: '/resources' }}
                className="btn"
            >
                <Button
                    label={t('resourcesBlockResoultsNotFoundBtnGoBackToAllResources')}
                    className={styles.btn}
                    color="blue"
                    size="small"
                ></Button>
            </CustomLink>
        </div>
    );
}
