import { cloneElement } from 'react';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';

import styles from './TopBar.module.css';

const Content = () => {
    const { t } = useTranslation('pages/resources/_blocks/header');

    return (
        <div className={styles.in}>
            <CustomLink
                href={{ pathname: '/resources' }}
                className={styles.headline}
                data-ats-resources-header="home_link"
                data-track="home_link"
            >
                {t('resourcesBarHeader')}
            </CustomLink>
        </div>
    );
};

export default function CertificationBanner({ container }) {
    return (
        <section className={styles.bar}>
            {cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
