import React from 'react';

import PropTypes from 'prop-types';

import styles from './FieldGroup.module.css';

const FieldGroup = ({ children, ...props }) => {
    return (
        <div
            {...props}
            className={[styles['group'], styles[`${props.variant}`]].join(' ')}
        >
            {children}
        </div>
    );
};

FieldGroup.propTypes = {
    variant: PropTypes.oneOf(['horizontal', 'vertical']),
    children: PropTypes.node.isRequired,
};

FieldGroup.defaultProps = {
    variant: 'vertical',
};

export default FieldGroup;
