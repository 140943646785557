import React from 'react';

import PropTypes from 'prop-types';

import styles from './Checkbox.module.css';

const Checkbox = ({ label, checked, fieldname, onCheckboxChange, onHover, ...props }) => {
    return (
        <div
            {...props}
            className={styles['checkbox-wrapprer']}
        >
            <label
                className={styles.label}
                onMouseEnter={onHover}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    name={fieldname}
                    onChange={onCheckboxChange}
                    className={styles['checkbox']}
                />
                <span>{label}</span>
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    fieldname: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    count: PropTypes.number,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;
