import React, { useEffect, useState } from 'react';

import { renderItem } from '@helpers/utils';

import useTranslation from '@hooks/useTranslation';
import Downshift from 'downshift';
import PropTypes from 'prop-types';

export default function DownshiftInputField({ value, downshiftInputOnChange, inputProps, menuProps, callback }) {
    const { t } = useTranslation('pages/resources/_blocks/search');

    const [resourceSuggestionItems, setResourceSuggestionItems] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState(value || '');
    useEffect(() => {
        if (!value) {
            setSearchInputValue('');
        }
    }, [value]);

    let requestTimeout = null;

    const downshiftInputStateOnChange = (event) => {
        if (!event.inputValue) {
            if (event.type === Downshift.stateChangeTypes.changeInput) {
                setResourceSuggestionItems([]);
            }
            return;
        }
        if (event.type === Downshift.stateChangeTypes.changeInput && event.inputValue.length) {
            clearTimeout(requestTimeout);

            requestTimeout = setTimeout(async () => {
                await fetchResourceSuggestionItems(event.inputValue, callback);
            }, 200);
        }
    };

    function fetchResourceSuggestionItems(event, callback) {
        callback(event).then((data) => {
            if (event === '') {
                setResourceSuggestionItems([]);
            } else {
                setResourceSuggestionItems(data);
            }
        });
    }

    return (
        <Downshift
            id="resources-autocomplete"
            onStateChange={downshiftInputStateOnChange}
            onChange={downshiftInputOnChange}
            onInputValueChange={setSearchInputValue}
            itemToString={(item) => (item ? item : searchInputValue)}
            items={resourceSuggestionItems}
            inputValue={searchInputValue}
        >
            {({ getInputProps, getItemProps, getLabelProps, getMenuProps, isOpen, inputValue, highlightedItem, highlightedIndex, selectedItem }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: t('resourcesBoxSearchFormSearch'),
                        })}
                        {...inputProps}
                    />
                    {isOpen && resourceSuggestionItems.length > 0 ? (
                        <ul
                            {...getMenuProps()}
                            {...menuProps}
                        >
                            {resourceSuggestionItems.map((item, index) => (
                                <li
                                    key={index}
                                    {...getItemProps({
                                        index,
                                        item,
                                        style: {
                                            background: highlightedIndex === index ? '#fafafa' : '',
                                            color: highlightedIndex === index ? '#000' : '#7d8f9f',
                                        },
                                    })}
                                >
                                    <span>{renderItem(item, inputValue)}</span>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            )}
        </Downshift>
    );
}

DownshiftInputField.propTypes = {
    downshiftInputOnChange: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
};
