import { forwardRef, useEffect, useRef, useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import { yupResolver } from '@hookform/resolvers/yup';
import useTranslation from '@hooks/useTranslation';
import { laravelRouteAboutContactUs } from '@static_components/laravelLinks';
import { multisiteRouteLegalPagePrivacy } from '@static_components/multisiteLinks';
import * as Yup from 'yup';

import styles from './NewsletterForm.module.css';

const ReCAPTCHA = dynamic(() => import('./WrappedReCAPTCHA'), {
    ssr: false,
});

const ForwardRefReCAPTCHA = forwardRef((props, ref) => (
    <ReCAPTCHA
        {...props}
        recaptchaRef={ref}
    />
));

const { publicRuntimeConfig } = getConfig();

export default function Form({ setThankYou }) {
    const { t } = useTranslation('pages/resources/paywall-form');
    const { geo } = useAppContext();
    const router = useRouter();
    const [isLoading, setLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);
    const [isRecaptchaOn, setIsRecaptchaOn] = useState(true);
    const { ref: refForm, inView: inViewForm } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const recaptchaRef = useRef();

    const schema = Yup.object().shape({
        // recaptcha: Yup.string().required(t('validation:captcha')),
        name: Yup.string().required(t('validation:required')).max(32, 'The name must be between 1-32 characters'),
        email: Yup.string().required(t('validation:required')).email(t('validation:email')).max(186, t('validation:invalid')),
    });

    useEffect(() => {
        fetch('/api/dms_reCaptcha', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.dms_reCaptcha === true) {
                    setIsRecaptchaOn(false);
                }
            });
    }, [setIsRecaptchaOn]);
    const GDPRInfo = () => (
        <div className={styles.terms}>
            <small
                dangerouslySetInnerHTML={{
                    __html: t('pages/resources/_blocks/newsletter:resourcesFormGdpr').replace(/:url/gi, laravelRouteAboutContactUs(router.locale)),
                }}
            ></small>
        </div>
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
        },
    });

    const onSubmit = async (form) => {
        setServerErrors([]);
        setLoading(true);
        setThankYou(false);
        // console.log(recaptchaRef);
        const formData = { name: form.name, email: form.email, marketing_consent: !!form.marketing_consent, language: router.locale };
        if (isRecaptchaOn) {
            const token = await recaptchaRef.current.executeAsync();
            formData.recaptcha = token;
        }

        try {
            const api = '/api/newsletter_subscribe';
            let response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            let data = await response.json();
            setLoading(false);

            if (data.success) {
                setThankYou(true);
            }

            if (data.errors) {
                setServerErrors(data.errors);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        let tmpval = event.target.value;
        if (tmpval === '') {
            event.target.classList.add(styles.empty);
            event.target.classList.remove(styles.notEmpty);
            event.target.parentElement.classList.remove(styles.hasSuccess);
        } else {
            event.target.classList.add(styles.notEmpty);
            event.target.classList.remove(styles.empty);
            setTimeout(() => {
                if (event.target.parentElement.classList.contains(styles.hasError)) {
                    event.target.parentElement.classList.remove(styles.hasSuccess);
                } else {
                    event.target.parentElement.classList.add(styles.hasSuccess);
                }
            }, 10);
        }
    };

    return (
        <form
            ref={refForm}
            name="newsletter"
            method="post"
            action="/resources#newsletter-section"
            id="form-newsletter"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            {serverErrors.length > 0 && (
                <ul className={styles.mainErrors}>
                    {serverErrors
                        .filter((error) => !error.field)
                        .map((error, i) => (
                            <li key={i}>{error.message}</li>
                        ))}
                </ul>
            )}

            <div className={styles.formGroup + ` ${(errors.name && styles.hasError) || ''}` + ` ${(serverErrors && serverErrors.find((error) => error.field == 'name') && styles.hasError) || ''}`}>
                <input
                    {...register('name', { onChange: handleChange })}
                    aria-label={t('resourcesFormName')}
                    type="text"
                    id="newsletter_name"
                    name="name"
                    required
                    data-ats-resources-newsletter-form="name_input"
                    data-track="newsletter_name_input"
                    data-test="newsletter_name_input"
                    className={styles.formControl}
                />
                <label htmlFor="newsletter_name">{t('resourcesFormName')}</label>
                {errors.name && <div className={styles.error}>{errors.name?.message}</div>}
                {serverErrors && serverErrors.find((error) => error.field == 'name') && <div className={styles.error}>{serverErrors.find((error) => error.field == 'name')?.message}</div>}
            </div>
            <div className={styles.formGroup + ` ${(errors.email && styles.hasError) || ''}` + ` ${(serverErrors && serverErrors.find((error) => error.field == 'email') && styles.hasError) || ''}`}>
                <input
                    {...register('email', { onChange: handleChange })}
                    aria-label={t('resourcesFormEmail')}
                    type="email"
                    id="newsletter_email"
                    name="email"
                    required
                    data-ats-resources-newsletter-form="email_input"
                    data-track="newsletter_email_input"
                    data-test="newsletter_email_input"
                    className={styles.formControl}
                />
                <label htmlFor="newsletter_email">{t('resourcesFormEmail')}</label>
                {errors.email && <div className={styles.error}>{errors.email?.message}</div>}
                {serverErrors && serverErrors.find((error) => error.field == 'email') && <div className={styles.error}>{serverErrors.find((error) => error.field == 'email')?.message}</div>}
            </div>
            <div className={`${styles.formGroup} ${styles.formGroupCheckbox}`}>
                <div className={styles.checkbox}>
                    <label htmlFor="newsletter_marketing_consent">
                        <input
                            {...register('marketing_consent')}
                            type="checkbox"
                            defaultValue="1"
                            id="newsletter_marketing_consent"
                            name="marketing_consent"
                            data-ats-resources-newsletter-form="marketing_consent"
                            data-track="newsletter_marketing_consent"
                        />
                        <div className={styles.customCheckbox}></div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('resourcesFormMarketingConsent').replace(/:url/gi, multisiteRouteLegalPagePrivacy(router.locale)),
                            }}
                        ></div>
                    </label>
                </div>
            </div>

            <div className={`${styles.formGroup} ${styles.buttonGroup}`}>
                <button
                    className={styles.btn}
                    disabled={isLoading}
                    type="submit"
                    id="newsletter_submit"
                    name="submit"
                    data-ats-resources-newsletter-form="button"
                    data-test="newsletter_submit"
                >
                    {t('pages/resources/_blocks/newsletter:resourcesBoxNewsletterFormSubmit')}
                </button>
            </div>

            {geo?.is_in_european_union && <GDPRInfo />}

            {inViewForm && isRecaptchaOn && (
                <ForwardRefReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={publicRuntimeConfig.recaptcha_sitekey}
                />
            )}
        </form>
    );
}
