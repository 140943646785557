import React, { useState } from 'react';

import { ReactComponent as SVGOK } from '@public/pages/resources/main/ok.svg';

import useTranslation from '@hooks/useTranslation';

import Form from './Form';
import styles from './NewsletterForm.module.css';

const Content = () => {
    const { t } = useTranslation('pages/resources/paywall-form');

    const [thankYou, setThankYou] = useState(false);

    return (
        <>
            {thankYou ? (
                <div className={styles.thankYou}>
                    <SVGOK />
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: t('pages/resources/_blocks/newsletter:resourcesBoxNewsletterThankHeader'),
                        }}
                    ></h2>
                </div>
            ) : (
                <div className={styles.form}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('pages/resources/_blocks/newsletter:resourcesBoxNewsletterHeader'),
                        }}
                    ></span>

                    <Form setThankYou={setThankYou} />
                </div>
            )}
        </>
    );
};

export default function NewsletterForm({ container, ...props }) {
    return (
        <section
            className={styles.newsletter}
            data-test="resources-newsletter"
            id="newsletter-section"
        >
            {React.cloneElement(container, {
                children: <Content {...props} />,
            })}
        </section>
    );
}

//---------------------- USE INSTRUCTION ------------------------:

// import NewsletterForm from '@components/pages/resources/NewsletterForm';
// import Container from '@components/global/Container';

// <NewsletterForm container={<Container />} />
