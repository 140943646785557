export const addGetParam = (currentParams, newParam) => {
    if (typeof currentParams === 'undefined') {
        return newParam;
    }
    if (typeof currentParams === 'string') {
        return [currentParams, newParam];
    }
    return [...currentParams, newParam];
};
export const removeGetParam = (currentParams, newParam) => {
    if (typeof currentParams === 'string') {
        if (currentParams === newParam) {
            return null;
        }
        return currentParams;
    }
    return currentParams.filter((param) => param !== newParam);
};
export const cleanQuery = (query) => {
    return Object.fromEntries(Object.entries(query).filter(([_, v]) => v != null));
};
export const cleanUpParams = (currentList, available) => {
    if (Array.isArray(currentList)) {
        return currentList.filter((item) => available.includes(item));
    }
    return currentList;
};
