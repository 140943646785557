import React from 'react';

import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import promoEn from '@public/pages/resources/_blank/promo-en.png';
import promoEn2x from '@public/pages/resources/_blank/promo-en@2x.png';
import promoPl from '@public/pages/resources/_blank/promo-pl.jpg';
import promoPl2x from '@public/pages/resources/_blank/promo-pl@2x.jpg';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteFeaturesConversionFunnel } from '@static_components/laravelLinks';
import {
    multisiteRouteCustomersPostAlexterrier,
    multisiteRouteCustomersPostInfoshareacademy,
    multisiteRouteCustomersPostLandcafe,
    multisiteRouteCustomersPostSubmissiontechnology,
    multisiteRouteResourcesReportsEmailMarketingBenchmarks,
} from '@static_components/multisiteLinks';

import styles from './MarketingLinks.module.css';

const Content = ({ blogData, ...props }) => {
    const { t } = useTranslation('pages/resources/_blocks/news');
    const { locale } = useRouter();

    return (
        <div className={styles.inner}>
            <div
                className={styles.articles}
                data-test="resources-news-blog"
                data-ats-resources-news="blog"
                data-track="news_blog"
            >
                <span>{t('resourcesBoxNewsHeader')}</span>
                <ul>
                    {blogData &&
                        blogData.slice(0, 3).map((post, i) => {
                            return (
                                <li key={i}>
                                    <a
                                        href={post.link}
                                        data-ats-resources-news="blog_item_link"
                                        data-track="news_blog_item_link"
                                        dangerouslySetInnerHTML={{
                                            __html: post.title,
                                        }}
                                    ></a>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div
                className={styles.articles}
                data-test="resources-news-casestudies"
                data-ats-resources-news="casestudies"
                data-track="news_casestudies"
            >
                <span>Case study</span>
                {(locale == 'pl' && (
                    <ul>
                        <li>
                            <a
                                href={multisiteRouteCustomersPostInfoshareacademy(locale)}
                                data-ats-resources-news="casestudies_item_link"
                                data-track="news_casestudies_item_link"
                            >
                                <strong>InfoShare Academy</strong> - 1200 nowych kontaktów w ciągu miesiąca dzięki kampanii z wykorzystaniem ebooka
                            </a>
                        </li>
                        <li>
                            <a
                                href={multisiteRouteCustomersPostLandcafe(locale)}
                                data-ats-resources-news="casestudies_item_link"
                                data-track="news_casestudies_item_link"
                            >
                                <strong>LandCafe.pl</strong> - sprzedaż na poziomie 54% dzięki angażującym kampaniom edukacyjnym
                            </a>
                        </li>
                        <li>
                            <a
                                href={multisiteRouteCustomersPostAlexterrier(locale)}
                                data-ats-resources-news="casestudies_item_link"
                                data-track="news_casestudies_item_link"
                            >
                                <strong>Alex Terrier</strong> - wskaźnik zapisu na listę na poziomie 19% dzięki darmowym treściom wysokiej jakości
                            </a>
                        </li>
                    </ul>
                )) ||
                    (locale == 'it' && (
                        <ul>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostAlexterrier(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>Alex Terrier</strong> - +19% di registrazioni alla newsletter grazie a contenuti gratuiti di alta qualità
                                </a>
                            </li>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostLandcafe(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>LandCafe.pl</strong> - +54% di incremento nelle vendite grazie alla campagna educational
                                </a>
                            </li>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostSubmissiontechnology(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>Submission Technology</strong> - CTR unico al 7,31% grazie agli A/B testing
                                </a>
                            </li>
                        </ul>
                    )) || (
                        <ul>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostAlexterrier(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>Alex Terrier</strong> - 19% email signup rate with free high-quality content
                                </a>
                            </li>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostLandcafe(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>LandCafe.pl</strong> - 54% of sales thanks to the educational campaign
                                </a>
                            </li>
                            <li>
                                <a
                                    href={multisiteRouteCustomersPostSubmissiontechnology(locale)}
                                    data-ats-resources-news="casestudies_item_link"
                                    data-track="news_casestudies_item_link"
                                >
                                    <strong>Submission Technology</strong> - 7.31% unique click-through rate with systematic A/B testing
                                </a>
                            </li>
                        </ul>
                    )}
            </div>
            <div
                className={styles.promo}
                data-ats-resources-news="banner"
                data-track="news_banner"
            >
                {(locale == 'pl' && (
                    <figure>
                        <a
                            href={laravelRouteFeaturesConversionFunnel(locale)}
                            data-ats-resources-news="banner_item_link"
                            data-track="news_banner_item_link"
                        >
                            <picture>
                                <source srcSet={`${promoPl.src} 1x, ${promoPl2x.src} 2x`} />
                                <img
                                    loading="lazy"
                                    src={promoPl.src}
                                    width={promoPl.width}
                                    height={promoPl.height}
                                    alt="GetResponse Autofunnel"
                                />
                            </picture>
                        </a>
                    </figure>
                )) ||
                    (locale == 'it' && (
                        <figure>
                            <a
                                href={multisiteRouteResourcesReportsEmailMarketingBenchmarks(locale)}
                                data-ats-resources-news="banner_item_link"
                                data-track="news_banner_item_link"
                            >
                                <picture>
                                    <source srcSet={`${promoEn.src} 1x, ${promoEn2x.src} 2x`} />
                                    <img
                                        loading="lazy"
                                        src={promoEn.src}
                                        width={promoEn.width}
                                        height={promoEn.height}
                                        alt="Email Marketing Benchmark"
                                    />
                                </picture>
                            </a>
                        </figure>
                    )) || (
                        <figure>
                            <CustomLink
                                href={{ pathname: '/resources/reports/email-marketing-benchmarks' }}
                                data-ats-resources-news="banner_item_link"
                                data-track="news_banner_item_link"
                            >
                                <picture>
                                    <source srcSet={`${promoEn.src} 1x, ${promoEn2x.src} 2x`} />
                                    <img
                                        loading="lazy"
                                        src={promoEn.src}
                                        width={promoEn.width}
                                        height={promoEn.height}
                                        alt="Email Marketing Benchmark"
                                    />
                                </picture>
                            </CustomLink>
                        </figure>
                    )}
            </div>
        </div>
    );
};

export default function MarketingLinks({ container, ...props }) {
    return (
        <section className={styles.news}>
            {React.cloneElement(container, {
                children: <Content {...props} />,
            })}
        </section>
    );
}
