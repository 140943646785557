import * as React from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';

import styles from './CertificationBanner.module.css';
import { ReactComponent as CapSVG } from './assets/cap.svg';

const Content = () => {
    const { t } = useTranslation('pages/resources/_blocks/certification');
    const { locale } = useRouter();

    const url: Record<string, string> = {
        pl: 'https://akademia.getresponse.pl',
        default: 'https://academy.getresponse.com',
    };

    const certificationUrl = url[locale as keyof typeof url] || url.default;
    return (
        <div className={styles.outer}>
            <div className={styles.inner}>
                <h5>
                    <Trans
                        i18nKey={'bannerText'}
                        components={{
                            span: <span />,
                        }}
                        ns="pages/resources/_blocks/certification"
                    />
                </h5>
                <Button
                    className={styles.button}
                    type={'button'}
                    href={certificationUrl}
                    label={t('resourcesCertificationBtn')}
                    size={'large'}
                />
            </div>
            <CapSVG />
            <CapSVG />
            <CapSVG />
            <CapSVG />
            <CapSVG />
            <CapSVG />
        </div>
    );
};

export default function CertificationBanner() {
    return (
        <section className={styles.certification}>
            <Content />
        </section>
    );
}
