import React, { useEffect, useMemo, useState } from 'react';

import getConfig from 'next/config';
import { useRouter } from 'next/router';

import Container from '@components/global/Container';
import CustomLink from '@components/global/CustomLink';
import Pagination from '@components/global/Pagination/themed/legacy';
import SearchBar from '@components/global/SearchBar';
import FiltersDivider from '@components/pages/resources/FiltersDivider';
import ListHeader from '@components/pages/resources/ListHeader';
import NoResults from '@components/pages/resources/NoResults';
import ResourceCard from '@components/pages/resources/ResourceCard';
import { DATE_DESC, NAME_DESC, VIEW_TYPE_BOX, VIEW_TYPE_LIST } from '@components/pages/resources/constants';
import Checkbox from '@components/ui/Checkbox';
import FieldGroup from '@components/ui/FieldGroup';
import MultipleCheckboxes from '@components/ui/MultipleCheckboxes';

import { addGetParam, cleanQuery, cleanUpParams, removeGetParam } from '@helpers/queryModifications';

import arrowSVG from '@public/pages/resources/global/arrow.svg';

import { getLocalizedUrl } from '@/lib/routing';
import useTranslation from '@hooks/useTranslation';
import PropTypes from 'prop-types';

import styles from './ResourceList.module.css';

const { publicRuntimeConfig } = getConfig();

const Picture = ({ title, imgSrc, imgSrc2x }) => {
    return (
        <picture>
            <source srcSet={`${imgSrc} 123w, ${imgSrc2x} 246w`} />
            <img
                decoding="async"
                src={imgSrc2x}
                alt={title}
                width="123"
                height="182"
            />
        </picture>
    );
};

export const Content = ({ query, searchQuery, sort = DATE_DESC, roles, types, topics, resources, totalResults, page, hideAside }) => {
    const { t } = useTranslation('pages/resources/_blocks/filters');
    const router = useRouter();
    const [viewType, setViewType] = useState(VIEW_TYPE_BOX);
    const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false);
    const hideFilters = roles.length <= 1 && topics.length <= 1 && types.length <= 1;
    // const domain = publicRuntimeConfig.domains_root[router.locale];

    const urlToAPI = '/api/v2/resources/autocomplete';
    function callback(query) {
        return fetch(urlToAPI + `?query=${query.toLowerCase()}`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json());
    }

    const postTypeTranslations = useMemo(
        () => ({
            videos: t('resourcesPostTypesVideos'),
            guides: t('resourcesPostTypesGuides'),
            infographics: t('resourcesPostTypesInfographics'),
            reports: t('resourcesPostTypesReports'),
            courses: t('resourcesPostTypesCourses'),
            collections: t('resourcesPostTypesCollections'),
            webinars: t('resourcesPostTypesWebinars'),
        }),
        [t],
    );

    const downshiftInputOnChange = (selection) => {
        if (!selection) {
            return;
        }
        let newQuery = { ...query, query: selection };
        delete newQuery.p;
        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: false },
        );
    };
    function handleOnSubmit(inputValue) {
        let newQuery = { query: inputValue };
        if (!inputValue) {
            delete newQuery.query;
        }
        delete newQuery.p;

        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: true },
        );
    }

    const handleRoleChange = (selected, value, prefetch = false) => {
        let newQuery;
        if (selected) {
            newQuery = { ...query, 'company-or-business-role': removeGetParam(query['company-or-business-role'], value) };
        } else {
            newQuery = { ...query, 'company-or-business-role': addGetParam(query['company-or-business-role'], value) };
        }
        delete newQuery.p;
        newQuery['company-or-business-role'] = cleanUpParams(
            newQuery['company-or-business-role'],
            roles.map((role) => role.key),
        );

        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: true },
        );
    };
    const handleTopicChange = (selected, value, prefetch = false) => {
        let newQuery;
        if (selected) {
            newQuery = { ...query, category: removeGetParam(query.category, value) };
        } else {
            newQuery = { ...query, category: addGetParam(query.category, value) };
        }
        delete newQuery.p;
        newQuery.category = cleanUpParams(
            newQuery.category,
            topics.map((topic) => topic.key),
        );

        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: true },
        );
    };
    const handleTypeChange = (selected, value, prefetch = false) => {
        let newQuery;
        if (selected) {
            newQuery = { ...query, type: removeGetParam(query.type, value) };
        } else {
            newQuery = { ...query, type: addGetParam(query.type, value) };
        }
        delete newQuery.p;
        newQuery.type = cleanUpParams(
            newQuery.type,
            types.map((type) => type.key),
        );

        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: true },
        );
    };
    const pageLink = (p) => {
        let newQuery = { ...query, p };
        if (p === 1) {
            delete newQuery.p;
        }
        if (p === page) {
            return null;
        }

        return (
            <CustomLink
                prefetch={false}
                href={{
                    pathname: '/resources',
                    search: Object.keys(newQuery).length > 0 ? `?${new URLSearchParams(newQuery).toString()}` : null,
                }}
            />
        );
    };

    return (
        <main className={[styles.main, hideAside === true || hideFilters === true ? styles.hideAside : ''].join(' ')}>
            <div className={styles.header}>
                <Container>
                    <h1 className={styles.header}>{t('pages/resources/_blocks/search:resourcesBoxSearchHeader')}</h1>
                    <SearchBar
                        query={searchQuery}
                        handleOnSubmit={handleOnSubmit}
                        downshiftInputOnChange={downshiftInputOnChange}
                        callback={callback}
                        buttonProps={{ 'data-ats-resources-search-form': 'search_button', 'data-track': 'search_button' }}
                    />
                </Container>
            </div>
            {resources.length > 0 ? (
                <>
                    <div className={styles.listHeader}>
                        <ListHeader
                            query={query}
                            totalResults={totalResults}
                            sort={sort}
                            viewType={viewType}
                            roles={roles}
                            topics={topics}
                            types={types}
                            onViewTypeChange={(type) => setViewType(type)}
                        />
                    </div>
                    <div className={[styles.list, styles['viewType--' + viewType]].join(' ')}>
                        {resources.map((resource) => (
                            <ResourceCard
                                key={resource.url}
                                link={resource.url}
                                title={typeof resource.title === 'string' ? resource.title : resource.title.join('...')}
                                description={viewType === VIEW_TYPE_LIST ? resource.excerpt : null}
                                picture={
                                    resource.cover ? (
                                        <Picture
                                            title={typeof resource.title === 'string' ? resource.title : resource.title.join('...')}
                                            imgSrc={`${publicRuntimeConfig.resizer}123x/${resource.cover}`}
                                            imgSrc2x={`${publicRuntimeConfig.resizer}246x/${resource.cover}`}
                                        />
                                    ) : null
                                }
                                postType={{
                                    key: resource.post_type,
                                    name: postTypeTranslations[resource.post_type],
                                }}
                                postCategories={resource.post_categories.map((category) => ({ name: category }))}
                                companyOrBusinessRole={resource.company_or_business_role && resource.company_or_business_role.map((role) => ({ name: role }))}
                                collectionsAmount={resource.collections_amount ? resource.collections_amount : null}
                            />
                        ))}
                    </div>
                    <div className={styles.pagination}>
                        <Pagination
                            path={'resources'}
                            className={styles.pagination}
                            currentPage={page || 1}
                            totalCount={totalResults}
                            pageSize={12}
                            visibleElement={3}
                            siblingCount={2}
                            pageLinkComponent={(p) => pageLink(p)}
                        />
                    </div>
                    {hideAside === false && hideFilters === false && (
                        <aside className={[styles.filters, mobileFiltersVisible ? styles.filtersVisible : ''].join(' ')}>
                            <button
                                type="button"
                                className={styles.mobileFiltersHide}
                                onClick={() => setMobileFiltersVisible(false)}
                                aria-label="hide filters"
                            ></button>
                            <button
                                type="button"
                                className={styles.mobileFiltersShow}
                                onClick={() => (mobileFiltersVisible ? setMobileFiltersVisible(false) : setMobileFiltersVisible(true))}
                            >
                                <img
                                    src={arrowSVG.src}
                                    width={arrowSVG.width}
                                    height={arrowSVG.height}
                                    alt="show filters"
                                />
                            </button>

                            <div className={styles.filtersForm}>
                                {roles.length > 1 && (
                                    <>
                                        <MultipleCheckboxes title={t('pages/resources/_blocks/filters:resourcesFiltersCompanyOrBusinessRole')}>
                                            <FieldGroup
                                                data-test="resources-filters-company-or-business-role"
                                                variant="vertical"
                                            >
                                                <>
                                                    {roles.map((role) => (
                                                        <Checkbox
                                                            key={role.key}
                                                            data-ats-resources-filters="company_or_business_role_item"
                                                            data-track="filters_company_or_business_role_item"
                                                            label={`${role.key} (${role.doc_count})`}
                                                            onCheckboxChange={() => handleRoleChange(role.selected, role.key)}
                                                            checked={role.selected}
                                                            fieldname={'company-or-business-role'}
                                                        />
                                                    ))}
                                                </>
                                            </FieldGroup>
                                        </MultipleCheckboxes>
                                        <FiltersDivider />
                                    </>
                                )}
                                {topics.length > 1 && (
                                    <>
                                        <MultipleCheckboxes title={t('pages/resources/_blocks/filters:resourcesFiltersTopics')}>
                                            <FieldGroup
                                                data-test="resources-filters-company-or-business-role"
                                                variant="vertical"
                                            >
                                                {topics.map((topic) => (
                                                    <Checkbox
                                                        key={topic.key}
                                                        data-ats-resources-filters="topics_item"
                                                        data-track="filters_topics_item"
                                                        label={`${topic.key} (${topic.doc_count})`}
                                                        onCheckboxChange={() => handleTopicChange(topic.selected, topic.key)}
                                                        checked={topic.selected}
                                                        fieldname={'category'}
                                                    />
                                                ))}
                                            </FieldGroup>
                                        </MultipleCheckboxes>
                                        <FiltersDivider />
                                    </>
                                )}
                                {types.length > 1 && (
                                    <MultipleCheckboxes title={t('pages/resources/_blocks/filters:resourcesFiltersType')}>
                                        <FieldGroup
                                            data-test="resources-filters-company-or-business-role"
                                            variant="vertical"
                                        >
                                            <>
                                                {types.map((type) => (
                                                    <Checkbox
                                                        key={type.key}
                                                        data-ats-resources-filters="types_item"
                                                        data-track="filters_types_item"
                                                        label={`${postTypeTranslations[type.key]} (${type.doc_count})`}
                                                        onCheckboxChange={() => handleTypeChange(type.selected, type.key)}
                                                        checked={type.selected}
                                                        fieldname={'type'}
                                                    />
                                                ))}
                                            </>
                                        </FieldGroup>
                                    </MultipleCheckboxes>
                                )}
                            </div>
                        </aside>
                    )}
                </>
            ) : (
                <div className={styles.noResults}>
                    <NoResults />
                </div>
            )}
        </main>
    );
};

export default function ResourceList({ container, ...props }) {
    return (
        <section className={styles.section}>
            {React.cloneElement(container, {
                children: <Content {...props} />,
            })}
        </section>
    );
}

Picture.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgSrc2x: PropTypes.string.isRequired,
};

Content.propTypes = {
    query: PropTypes.object,
    searchQuery: PropTypes.string,
    sort: PropTypes.oneOf([DATE_DESC, NAME_DESC]),
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
    topics: PropTypes.arrayOf(PropTypes.object).isRequired,
    resources: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalResults: PropTypes.number.isRequired,
    page: PropTypes.number,
};
