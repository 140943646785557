import { useMemo } from 'react';

import { useRouter } from 'next/router';

import { DATE_DESC, NAME_DESC, VIEW_TYPE_BOX, VIEW_TYPE_LIST } from '@components/pages/resources/constants';

import { cleanQuery, cleanUpParams, removeGetParam } from '@helpers/queryModifications';

import { ReactComponent as SVGBox } from '@public/pages/resources/list-header/box.svg';
import { ReactComponent as SVGList } from '@public/pages/resources/list-header/list.svg';
import removeSVG from '@public/pages/resources/list-header/remove.svg';

import { getLocalizedUrl } from '@/lib/routing';
import useTranslation from '@hooks/useTranslation';
import PropTypes from 'prop-types';

import styles from './ListHeader.module.css';

export default function ListHeader({ query, totalResults, sort, viewType, roles, topics, types, onViewTypeChange }) {
    const { t } = useTranslation('pages/resources/_blocks/results');
    const router = useRouter();

    const postTypeTranslations = useMemo(
        () => ({
            videos: t('pages/resources/_blocks/filters:resourcesPostTypesVideos'),
            guides: t('pages/resources/_blocks/filters:resourcesPostTypesGuides'),
            infographics: t('pages/resources/_blocks/filters:resourcesPostTypesInfographics'),
            reports: t('pages/resources/_blocks/filters:resourcesPostTypesReports'),
            courses: t('pages/resources/_blocks/filters:resourcesPostTypesCourses'),
            collections: t('pages/resources/_blocks/filters:resourcesPostTypesCollections'),
            webinars: t('pages/resources/_blocks/filters:resourcesPostTypesWebinars'),
        }),
        [t],
    );

    const setViewType = (viewType) => {
        onViewTypeChange(viewType);
    };

    const selectedRoles = roles.filter((role) => role.selected).map((role) => ({ ...role, label: role.key, category: 'role' }));
    const selectedTypes = types.filter((type) => type.selected).map((type) => ({ ...type, label: postTypeTranslations[type.key], category: 'type' }));
    const selectedTopics = topics.filter((topic) => topic.selected).map((topic) => ({ ...topic, label: topic.key, category: 'topic' }));

    const handleSortChange = (e) => {
        let newQuery;
        if (e.target.value === DATE_DESC) {
            newQuery = { ...query, sort: null };
        } else {
            newQuery = { ...query, sort: e.target.value };
        }
        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: false },
        );
    };
    const handleFilterRemove = (value, category) => {
        let newQuery = { ...query };
        if (category === 'role') {
            newQuery = { ...query, 'company-or-business-role': removeGetParam(query['company-or-business-role'], value) };
            newQuery['company-or-business-role'] = cleanUpParams(
                newQuery['company-or-business-role'],
                roles.map((roles) => roles.key),
            );
        } else if (category === 'topic') {
            newQuery = { ...query, category: removeGetParam(query.category, value) };
            newQuery.category = cleanUpParams(
                newQuery.category,
                topics.map((topic) => topic.key),
            );
        } else if (category === 'type') {
            newQuery = { ...query, type: removeGetParam(query.type, value) };
            newQuery.type = cleanUpParams(
                newQuery.type,
                types.map((type) => type.key),
            );
        }
        delete newQuery.p;
        router.push(
            {
                pathname: getLocalizedUrl('/resources', router.locale),
                query: cleanQuery(newQuery),
            },
            null,
            { scroll: false },
        );
    };

    return (
        <div className={styles.manager}>
            <div className={styles.filters}>
                {[...selectedRoles, ...selectedTopics, ...selectedTypes].length > 0 ? (
                    <>
                        <div
                            className={styles.filtersLabel}
                            dangerouslySetInnerHTML={{ __html: t('resourcesResultsCountResultsWithFilters').replace(':count', totalResults) }}
                        ></div>

                        <div className={styles.bubbles}>
                            {[...selectedRoles, ...selectedTopics, ...selectedTypes].map((filter) => (
                                <span
                                    key={filter.key}
                                    className={styles.bubble}
                                >
                                    {filter.label}
                                    <button
                                        type="button"
                                        onClick={() => handleFilterRemove(filter.key, filter.category)}
                                    >
                                        <img
                                            src={removeSVG.src}
                                            width={removeSVG.width}
                                            height={removeSVG.height}
                                            alt="remove"
                                        />
                                    </button>
                                </span>
                            ))}
                        </div>
                    </>
                ) : (
                    <div>
                        {t('resourcesResultsCountResults')}{' '}
                        <strong
                            data-ats-resources-search-results="counter"
                            data-track="search_counter"
                        >
                            {totalResults}
                        </strong>
                    </div>
                )}
            </div>
            <div className={styles.view}>
                <span className={styles.sort}>
                    <select
                        value={sort || DATE_DESC}
                        onChange={handleSortChange}
                        className={styles.select}
                        data-ats-resources-search-results="sort_select"
                        data-track="search_sort_select"
                        aria-label="sort type"
                    >
                        <option value="date_desc">{t('resourcesResultsSortAdded')}</option>
                        <option value="name_asc">{t('resourcesResultsSortName')}</option>
                    </select>
                </span>
                <ul
                    className={styles.viewType}
                    data-test="resources-view-type"
                    data-ats-resources-search-results="view_type"
                    data-track="search_view_type"
                >
                    <li className={viewType === VIEW_TYPE_BOX ? styles.active : undefined}>
                        <button
                            onClick={() => setViewType(VIEW_TYPE_BOX)}
                            type="button"
                            aria-label="box view"
                        >
                            <SVGBox
                                width="15"
                                height="15"
                            />
                        </button>
                    </li>
                    <li className={viewType === VIEW_TYPE_LIST ? styles.active : undefined}>
                        <button
                            onClick={() => setViewType(VIEW_TYPE_LIST)}
                            type="button"
                            aria-label="list view"
                        >
                            <SVGList
                                width="16"
                                height="15"
                            />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

ListHeader.propTypes = {
    query: PropTypes.object,
    totalResults: PropTypes.number.isRequired,
    sort: PropTypes.oneOf([DATE_DESC, NAME_DESC]),
    viewType: PropTypes.oneOf([VIEW_TYPE_BOX, VIEW_TYPE_LIST]).isRequired,
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    topics: PropTypes.arrayOf(PropTypes.object).isRequired,
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
    onViewTypeChange: PropTypes.func.isRequired,
};
